
export class PhoneProfileInfo {
  id = null
  phoneId = ''
  msisdn = ''
  userId = null
  name = ''
  description = ''
  commercialAddress = ''
  contactEmail = ''
  stateText = ''
  commercialWebsite = ''
  additionalUrl = ''
  businessCategory = null
  brandImageUrl = ''
  brandImageMime = ''

  constructor (data) {
    this.loadData(data)
  }

  // eslint-disable-next-line complexity
  loadData (data) {
    if (!data) return
    this.id = data.id || ''
    this.phoneId = data.phone_id || ''
    this.msisdn = data.msisdn || ''
    this.name = data.name || ''
    this.userId = data.user_id
    this.description = data.description || ''
    this.commercialAddress = data.commercial_address || ''
    this.contactEmail = data.contact_email || ''
    this.stateText = data.state_text || ''
    this.commercialWebsite = data.commercial_website || ''
    this.additionalUrl = data.additional_url || ''
    this.businessCategory = data.business_category || null
    this.brandImageUrl = data.brand_image_url || ''
    this.brandImageMime = data.brand_image_mime || ''
  }
}
