import { WhatsappWebhook } from './whatsapp-webhook.service'

const PATH = 'whatsapp/'

class WhatsappDataService extends WhatsappWebhook {
  updateProfile (data) {
    if (!this.provider) {
      throw new Error('Setting a provider is needed for this operation. i.e.: WhatsappDataService.setProvider(...)')
    }
    return this.post(PATH + this.provider + '/profile-info', {
      type: 'PROFILE',
      ...data,
    })
  }
}

export default new WhatsappDataService()
