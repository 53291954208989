import { Service } from '@/services/service'

const PATH = 'whatsapp/phone-profile/'

class PhoneProfileInfoService extends Service {
  save (params = {}) {
    return this.post(PATH + 'save', params)
  }

  saveByMsisdn (params = {}) {
    return this.post(PATH + 'save-by-msisdn', params)
  }

  update (params = {}) {
    return this.post(PATH + 'update', params)
  }

  getPhoneProfile (params = {}) {
    return this.get(PATH + 'profile', params)
  }

  getPhoneProfileByMsisdn (params = {}) {
    return this.get(PATH + 'profile-by-msisdn', params)
  }
}

export default new PhoneProfileInfoService()
